import type { NextPage } from 'next'
import MainLayout from "../app/components/Layouts/MainLayout";
import Helper from "../app/modules/Helper";
import {useEffect, useState} from "react";

const Home: NextPage = () => {
    const [width,setWidth] = useState(0)
    useEffect(function (){
        setWidth(document.body.clientWidth)
    },[])
    return (
        <MainLayout>
            <div id="main-area">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="text-center not-found-container" >
                                <div className="title-404">
                                    404
                                </div>
                                <div className="desc-404">
                                    {Helper.trans('notFound')}
                                </div>

                                <div className="mt-4">
                                    <a href={'/'} className="btn btn-yellow">
                                        <span>{Helper.trans('returnHome')} </span>
                                        <span><i className="icon-arrow"/></span>
                                    </a>

                                </div>
                                <br/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MainLayout>
    );
}

export default Home
